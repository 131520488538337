<template>
  <li class="task-item">
    <div class="content">
      <h3>
        <p>{{ task.title }}</p>
        <i v-if="iconClass" :class="iconClass"></i>
      </h3>
      <p>{{ task.task_desc }}</p>
    </div>

    <button @click="handleTaskClick(task)">
      {{ task.button_txt }}
    </button>
  </li>
</template>

<script>
  export default {
    props: {
      task: {
        type: Object,
        required: true,
      },
      handlePost: {
        type: Function,
      },
    },
    computed: {
      iconClass() {
        if (this.task && this.task.reward_type === 'coin') {
          return 'icon-coin';
        } else if (this.task && this.task.reward_type === 'pendant') {
          return 'icon-gift';
        }
        return false;
      },
    },
    methods: {
      handleTaskClick() {
        this.handlePost();
      },
    },
  };
</script>

<style lang="less" scoped>
  .task-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 0.12rem;
    padding: 0.26rem 0.22rem;
    width: 6rem;
    height: 0.94rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .content {
      h3 {
        display: flex;
        align-items: center;

        p {
          font-size: 0.32rem;
          color: #333333;
          font-weight: 600;
          max-width: 3.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i {
          display: inline-block;
          width: 0.4rem;
          height: 0.4rem;
        }
        i.icon-coin {
          background: url('https://static1.kaixinyf.cn/img/lza6311520d8cb67502217213.png') no-repeat center center;
          background-size: cover;
        }
        i.icon-gift {
          background: url('https://static1.kaixinyf.cn/img/lza631152293cdd7511439912.png') no-repeat center center;
          background-size: cover;
        }
      }
      p {
        max-width: 3.86rem;
        font-size: 0.24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999999;
        letter-spacing: 1px;
      }
    }
    button {
      padding: 0 12px;
      height: 0.56rem;
      background: linear-gradient(90deg, #f87932 0%, #ff2b3d 100%);
      border-radius: 0.29rem;
      border: none;
      color: #ffffff;
      font-size: 0.24rem;
      font-weight: 600;
    }
  }
</style>
