<template>
  <div class="mid-autumn">
    <header>
      <img src="https://static1.kaixinyf.cn/img/lza631145148f6f2594541078.png" alt="bg" />
      <div class="tips"><span>活动结束后 随机抽百位参与者送大奖</span></div>
    </header>
    <div class="wrapper">
      <div class="task-list">
        <div class="task-list-top">
          <p>已有2313人参与领金币活动</p>
        </div>
        <div class="task-list-list">
          <div class="task-list-list-item" v-for="(item, index) in TaskList" :key="index">
            <TaskItem :task="item" :handlePost="handlePost" />
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <a :href="buttonLink"><button @click="handlePost">我要赚金币</button></a>
      </div>
    </div>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import TaskItem from './components/TaskItem.vue';
  import { mapGetters } from 'vuex';
  import Download from '@/utils/download';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  //   import API from '@/api';

  export default {
    components: {
      TaskItem,
      IntroModal,
    },
    data() {
      return {
        showIntro: false,
        buttonLink: 'javascript:void(0);',
      };
    },
    computed: {
      ...mapGetters('activity/midAutumn', ['TaskList']),
    },
    async asyncData({ store, $http, params }) {
      const { device_id, app_name, client_v } = params;
      //   const { task_list } = await $http.post(API.GET_ACTIVITY_AUTUMN_PAGE, {
      //     device_id,
      //     app_name,
      //     client_v,
      //   });

      //   store.commit('activity/midAutumn/SET_TASK_LIST', task_list);
    },
    mounted() {
      if (this.$platform.Android && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/activity/mid-autumn`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/activity/mid-autumn`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/activity/mid-autumn`;
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      handlePost() {
        try {
          let jump = () => {
            let url;

            if (this.$platform.Android && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>

<style lang="less" scoped>
  .mid-autumn {
    header {
      position: relative;
      img {
        display: inherit;
        height: 5.64rem;
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 1.42rem;
        bottom: 0.88rem;
        width: 4.66rem;
        height: 0.56rem;
        background: url('https://static1.kaixinyf.cn/img/lza63114542651ac690492322.png') no-repeat center center;
        background-size: cover;
        span {
          font-size: 0.24rem;
          font-weight: 600;
          color: #ffffff;
          text-shadow: 0px 2px 4px rgba(168, 0, 0, 0.5);
        }
      }
      .rule {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.44rem;
        right: 0;
        width: 0.84rem;
        height: 0.4rem;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 1rem 0px 0px 1rem;
        font-size: 0.26rem;
        color: #fff;
      }
    }
    .wrapper {
      width: 7.5rem;
      padding-bottom: 0.44rem;
      background: linear-gradient(180deg, #ffe4cd 0%, #fed0b9 100%);

      .task-list {
        margin: 0 auto;
        width: 6.94rem;
        height: 10.16rem;
        background: url('https://static1.kaixinyf.cn/img/lza63114afc479c4678735814.png') no-repeat center center;
        background-size: cover;
        padding-bottom: 0.74rem;
        &-top {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6.7rem;
          height: 1.52rem;
          background: url('https://static1.kaixinyf.cn/img/lza63114c6490baa792903130.png') no-repeat center center;
          background-size: cover;
          p {
            font-size: 0.28rem;
            font-weight: 600;
            color: #fef1d8;
            text-shadow: 0px 2px 4px rgba(168, 0, 0, 0.5);
          }
        }
        &-list {
          &-item {
            margin: 0.12rem auto 0;
          }
        }
      }
    }
    .bottom-btn {
      position: fixed;
      bottom: 0.52rem;
      display: flex;
      width: 6.9rem;
      margin-left: 0.3rem;
      justify-content: center;
      button {
        width: 4.6rem;
        height: 0.92rem;
        font-size: 0.28rem;
        font-weight: bold;
        color: #f7f7f7;
        border-radius: 0.46rem;
        border: none;
        background-color: #ff2b3d;
      }
    }
  }
</style>
